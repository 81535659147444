<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMetaStore } from '~/stores/meta.store';
import ListProjects from './list.vue';
import ShowProject from './show.vue';
import { useProjectsStore } from '~/stores/projects.store';

const route = useRoute();
const metaStore = useMetaStore();
const projectsStore = useProjectsStore();

metaStore.metaGetter();

await projectsStore.fetchProjects(route);
const data: any = ref(projectsStore.projects)
</script>

<template>
	<template v-if="data?.data && data?.model === 'ListProjects'">
		<ListProjects :data="data" />
	</template>
	<template v-else-if="data?._id && data?.model === 'ShowProject'">
		<ShowProject :data="data" />
	</template>
	<template v-else>
		<!-- TODO: Design no data section for projects -->
		<div class="text-center pb-10">
			<div class="text-2xl py-12">
				No projects to show
			</div>
			<a class="btn btn-primary p-3" href="/">Back To Home</a>
		</div>
	</template>
</template>
